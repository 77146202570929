<template>
  <div>
    <el-dialog v-model="dialogVisible" title="禁止记录" :close-on-click-modal="false" width="1300px" center>
      <el-form ref="searchForm" :model="searchForm" :inline="true" @keyup.enter="getData">
        <el-form-item label="车牌号" prop="car_number">
          <carNumber ref="carNumber" @inputCar="e => searchForm.car_number = e" />
          <!-- <el-input v-model="searchForm.car_number" placeholder="请输入车牌号"></el-input> -->
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getData">搜索</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>

      <el-table v-loading="loading" :data="tableData" :max-height="maxH" empty-text="暂无数据">
        <el-table-column prop="car_number" label="车牌号" width="130"> </el-table-column>
        <el-table-column prop="created_at" label="时间" width="180"> </el-table-column>
        <el-table-column prop="state" label="通行问题" width="180">
          <template #default="scope">
            {{ scope.row.state == 2 ? '禁止入场' : '禁止出场' }}
          </template>
        </el-table-column>
        <el-table-column prop="ent_gate_name" label="通道" width="120"></el-table-column>
        <el-table-column prop="type_name" label="类型" width="100"></el-table-column>

        <el-table-column prop="memo" label="备注"> </el-table-column>
        <el-table-column prop="forbid_pic" label="操作" width="120">
          <template #default="scope">
            <label class="preview-img" @click="previewImg(scope.row.forbid_pic)">查看图片</label>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :hide-on-single-page="false" layout="prev, pager, next" :page-size="searchForm.per_page"
        :total="total" @current-change="currentChange">
      </el-pagination>
    </el-dialog>
    <PreviewImg ref="PreviewImg" />

  </div>
</template>

<script>
import * as API_index from "@/api/index";
import carNumber from '@/components/car-number'
import PreviewImg from '@/components/PreviewImg'

export default {
  name: '',
  components: {
    carNumber,
    PreviewImg
  },
  props: {
    close: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      searchForm: {
        park_id: this.getStore('park_id'),
        car_number: '',
        page: 1,
        per_page: 10,
      },
      total: 0,
      tableData: [],
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    init() {
      this.dialogVisible = true
      this.getData()
    },
    currentChange(e) {
      this.searchForm.page = e
      this.getData()
    },
    getData() {
      this.loading = true
      API_index.getInout(this.searchForm).then(res => {
        this.loading = false
        this.tableData = res.data
        this.total = res.total
      })
    },

    resetForm() {
      this.$refs.searchForm.resetFields()
      this.$refs.carNumber.carNumber = ''
      this.getData()
    },
    previewImg(url) {
      this.$refs.PreviewImg.init(url)
    },
  },
}
</script>
<style lang="scss" scoped>
.preview-img {
  color: $primary;
  cursor: pointer;
}
</style>