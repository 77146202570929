<template>
  <div>
    <el-dialog v-model="dialogVisible" title="车辆信息" :close-on-click-modal="false" width="1300px" center>
      <el-form ref="searchForm" :model="searchForm" :inline="true" @keyup.enter="getData">
        <el-form-item label="车牌号" prop="car_number" class="car-number">
          <carNumber ref="carNumber" @inputCar="e => searchForm.car_number = e" />
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="searchForm.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="mobile">
          <el-input v-model="searchForm.mobile" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="searchForm.address" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getData">搜索</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>

      <el-table v-loading="loading" :data="tableData" :max-height="maxH" empty-text="暂无数据">
        <el-table-column prop="car_number" label="车牌号" width="130">
          <template #default="scope">
            <div v-if="scope.row.type == 4"
              v-html="scope.row.numbers && scope.row.numbers.map(e => e.car_number).join('<br />')"></div>
            <div v-else>{{ scope.row.car_number }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="type_name" label="车辆类型"></el-table-column>
        <el-table-column prop="park_rule_name" label="车辆规则" width="180"> </el-table-column>
        <el-table-column prop="valid_start_time" label="有效期" width="250">
          <template #default="scope">
            <div v-if="scope.row.valid_start_time" class="flex flex-ac">
              至<div style="margin-left: 10px;">
                {{ scope.row.valid_start_time }}<br />{{ scope.row.valid_end_time }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pos_num" label="车位数"></el-table-column>
        <el-table-column prop="contact_name" label="车主姓名"> </el-table-column>
        <el-table-column prop="contact_phone" label="电话" width="120"> </el-table-column>
        <el-table-column prop="balance" label="余额"> </el-table-column>
        <el-table-column prop="address" label="地址"> </el-table-column>
      </el-table>
      <el-pagination :hide-on-single-page="false" layout="prev, pager, next" :page-size="searchForm.per_page"
        :total="total" @current-change="currentChange">
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import * as API_index from "@/api/index";
import carNumber from '@/components/car-number'

export default {
  name: '',
  components: {
    carNumber,
  },
  props: {
    close: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      searchForm: {
        park_id: this.getStore('park_id'),
        car_number: '',
        name: '',
        mobile: '',
        address: '',
        page: 1,
        per_page: 10,
      },
      total: 0,
      tableData: [],
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    init() {
      this.dialogVisible = true
      this.getData()
    },
    currentChange(e) {
      this.searchForm.page = e
      this.getData()
    },
    getData() {
      this.loading = true
      API_index.getVelCarList(this.searchForm).then(res => {
        this.loading = false
        this.tableData = res.data
        this.total = res.total
      })
    },

    resetForm() {
      this.$refs.searchForm.resetFields()
      this.$refs.carNumber.carNumber = ''
      this.getData()
    },
  },
}
</script>
<style lang="scss" scoped>
.car-number {
  :deep(.el-input) {
    width: 200px !important;
  }
}

.preview-img {
  color: $primary;
  cursor: pointer;
}
</style>