<template>
  <div>
    <el-dialog v-model="dialogVisible" :title="title" :close-on-click-modal="false" width="1300px" center>
      <el-form ref="searchForm" :model="searchForm" :inline="true" @keyup.enter="getData(type)">
        <el-form-item label="车牌号" prop="car_number">
          <carNumber ref="carNumber" @inputCar="e => searchForm.car_number = e" />
          <!-- <el-input v-model="searchForm.car_number" placeholder="请输入车牌号"></el-input> -->
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getData(type)">搜索</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table v-loading="loading" :data="tableData" :max-height="maxH" empty-text="暂无数据" style="width: 100%">
        <el-table-column prop="car_number" label="车牌号" width="130"> </el-table-column>
        <el-table-column prop="type_name" label="类型"> </el-table-column>
        <el-table-column prop="ent_gate_name" label="入场通道"> </el-table-column>
        <el-table-column prop="memo" label="备注"> </el-table-column>
        <el-table-column v-if="type == 'bepresent'" prop="ent_state" label="入场类型">
          <template #default="scope">
            {{ scope.row.ent_state == '是' ? '手动入场' : '自动识别' }}
          </template>
        </el-table-column>
        <el-table-column prop="ent_time" label="入场时间"> </el-table-column>
        <el-table-column v-if="type == 'bepresent'" prop="time_long" label="停车时长" width="140"> </el-table-column>

        <el-table-column v-if="type == 'out'" prop="dep_gate_name" label="出场通道"> </el-table-column>
        <el-table-column v-if="type == 'out'" prop="dep_time" label="出场时间"> </el-table-column>
        <el-table-column v-if="type == 'out'" prop="pay_type" label="收费类型"> </el-table-column>
        <el-table-column v-if="type == 'out'" prop="price" label="金额"> </el-table-column>
        <el-table-column prop="ent_pic" label="入场抓拍照片" width="120">
          <template #default="scope">
            <label class="preview-img" @click="previewImg(scope.row.ent_pic)">查看图片</label>
            <!-- <el-image :src="scope.row.ent_pic" :preview-src-list="[scope.row.ent_pic]" /> -->
          </template>
        </el-table-column>
        <el-table-column v-if="type == 'out'" prop="dep_pic" label="出场抓拍照片" width="120">
          <template #default="scope">
            <label class="preview-img" @click="previewImg(scope.row.dep_pic)">查看图片</label>
            <!-- <el-image :src="scope.row.dep_pic" :preview-src-list="[scope.row.dep_pic]" /> -->
          </template>
        </el-table-column>
        <el-table-column v-if="type == 'bepresent'" prop="price1" label="操作" width="230">
          <template #default="scope">
            <el-button v-if="scope.row.type_name == '一位多车'" type="primary" plain @click="handleRelation(scope.row)"
              size="small">关联车</el-button>
            <el-button type="primary" plain @click="handleModify(scope.row)" size="small">修改</el-button>
            <el-button type="primary" @click="handleAppearance(scope.row)" size="small">出场</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :hide-on-single-page="false" layout="prev, pager, next" :page-size="searchForm.per_page"
        :total="total" @current-change="currentChange">
      </el-pagination>
    </el-dialog>

    <el-dialog v-model="dialogVisibleRelation" :close-on-click-modal="false" :title="title" width="600px" center>
      <div class="relation-title">
        <label>车位总数：{{ relationInfo.pos_num }}</label><label>车辆总数：{{ relationInfo.car_num }}</label>
      </div>
      <div class="relation-car">
        <label v-for="item in relationInfo.data" :key="item.id">{{ item.car_number }}<span
            :class="{ blue: item.in_record }">({{ item.in_record ? '在场' : '离场' }}),</span>
        </label>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogVisibleRelation = false">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="dialogVisibleModify" :close-on-click-modal="false" :title="title" width="600px" center>
      <el-form ref="elForm" :model="modifyForm" status-icon :rules="rules" label-width="120px" @keyup.enter="submit">
        <el-form-item label="原车牌">
          {{ modifyForm.r_car_number }}
        </el-form-item>
        <el-form-item label="新车牌" prop="car_number">
          <carNumber ref="carNumber" @inputCar="e => modifyForm.car_number = e" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button v-if="close" @click="dialogVisibleModify = false">取 消</el-button>
          <el-button type="primary" :loading="modifyLoading" @click="submitModify">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <PreviewImg ref="PreviewImg" />

  </div>
</template>

<script>
import * as API_index from "@/api/index";
import carNumber from '@/components/car-number'
import PreviewImg from '@/components/PreviewImg'

export default {
  name: '',
  components: {
    carNumber,
    PreviewImg
  },
  props: {
    title: {
      type: [String, Number],
      default: ''
    },
    close: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      searchForm: {
        park_id: this.getStore('park_id'),
        car_number: '',
        page: 1,
        per_page: 10,
      },
      total: 0,
      tableData: [],
      type: '',  //出入场类型
      dialogVisibleRelation: false, //关联
      relationInfo: {},
      relationType: ['', '月租车', 'VIP车', '储值车', '多位多车', '会员车'],
      dialogVisibleModify: false, //修改车牌号
      modifyForm: {
        park_id: '',
        id: '',
        car_number: '',
      },
      rules: {
        car_number: [
          {
            required: true,
            message: '请输入新车牌',
          },
        ],
      },
      modifyLoading: false, //提交修改车牌状态

    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    init(type) {
      this.dialogVisible = true
      this.type = type
      this.getData(type)
    },
    currentChange(e) {
      console.log(e)
      this.searchForm.page = e
      this.getData(this.type)
    },
    getData(type) {
      this.loading = true
      let ApiUrl = ''
      if (type == 'out') {
        ApiUrl = API_index.getRecordDepRecord
      } else if (type == 'enter') {
        ApiUrl = API_index.getRecordEntRecord
      } else if (type == 'bepresent') {
        ApiUrl = API_index.getRecordInRecord
      }
      ApiUrl(this.searchForm).then(res => {
        this.loading = false
        this.tableData = res.data
        this.total = res.total
      })
    },
    handleRelation(e) {
      let form = {
        car_number: e.car_number,
        park_id: e.park_id
      }
      API_index.getRecordRelation(form).then((res) => {
        this.dialogVisibleRelation = true
        this.relationInfo = res
      })

    },
    handleModify(e) {
      this.modifyForm.r_car_number = e.car_number
      this.modifyForm.park_id = e.park_id
      this.modifyForm.id = e.id
      this.dialogVisibleModify = true

    },
    submitModify() {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          this.modifyLoading = true
          API_index.postRecordRecordUpdate(this.modifyForm).then(() => {
            this.dialogVisibleModify = false
            this.modifyLoading = false

            this.searchForm.page = 1
            this.getData('bepresent')
          }).catch(() => {
            this.modifyLoading = false
          });
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleAppearance(e) {
      this.dialogVisible = false
      this.$emit('appearance', e.car_number)
      console.log(e)
    },
    resetForm() {
      this.$refs.searchForm.resetFields()
      this.$refs.carNumber.carNumber = ''
      this.getData(this.type)
    },
    previewImg(url) {
      this.$refs.PreviewImg.init(url)
    },
  },
}
</script>
<style lang="scss" scoped>
.relation-title {
  font-size: 24px;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;

  label:last-child {
    margin-left: 40px;
  }
}

.relation-car {
  padding: 20px;
  text-align: center;

  label {
    margin-right: 10px;
  }

  span {
    color: #aaa;
  }
}

.preview-img {
  color: $primary;
  cursor: pointer;
}
</style>