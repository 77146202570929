<template>
  <div>
    <!-- 查询弹窗 -->
    <el-dialog v-model="dialogVisibleLookup" :destroy-on-close="true" :title="title" :close-on-click-modal="false"
      width="810px" center>
      <el-form ref="elForm" class="form-lookup" :model="lookupForm" :rules="lookuRules" label-width="220px">
        <el-form-item label="车牌号" prop="car_number">
          <div class="flex">
            <carNumber ref="carNumber" @inputCar="e => lookupForm.car_number = e" />

            <!-- <el-input v-model="lookupForm.car_number" placeholder="请输入车牌号" style="width:340px"></el-input> -->
            <label v-if="type == 'enter'" class="ban-record" @click="getData">禁止入场记录</label>
            <label v-else-if="type == 'out'" class="ban-record" @click="handleBepresent">在场车辆</label>
          </div>
        </el-form-item>
        <el-form-item v-if="type == 'enter'" label="入场时间" prop="ent_time">
          <el-date-picker v-model="lookupForm.ent_time" type="datetime" placeholder="选择日期时间" style="width:340px">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="(type == 'out' ? '出' : '入') + '口通道'" prop="gate_id">
          <el-select v-model="lookupForm.gate_id" :placeholder="'请选择' + (type == 'out' ? '出' : '入') + '口通道'"
            style="width:340px">
            <el-option v-for="item in gateList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否开闸">
          <el-switch v-model="openthegate" @change="handleOpenthegate" />
        </el-form-item>
        <el-form-item v-if="type == 'enter'" label="入场照片" prop="pic">
          <div v-if="lookupForm.pic" class="pic">
            <i class="icon icon-shanchu2x" @click="lookupForm.pic = ''" />
            <img :src="lookupForm.pic" />
          </div>
          <el-upload v-else limit="1" list-type="picture-card" :auto-upload="false" :on-change="handleChange">
            <i class="el-icon-plus"></i>
          </el-upload>
          <!-- <el-input v-model="lookupForm.pic"></el-input> -->
        </el-form-item>
        <el-form-item v-if="type == 'enter'" label="备注" prop="memo">
          <el-input v-model="lookupForm.memo" placeholder="请输入备注" style="width:340px"></el-input>
        </el-form-item>
        <el-form-item v-if="type == 'out'" label="计费规则" prop="prop_rule_id">
          <el-select v-model="lookupForm.prop_rule_id" :placeholder="'请选择' + (type == 'out' ? '出' : '入') + '口通道'"
            style="width:340px">
            <el-option v-for="item in ruleList" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="outAndEnterLoading" @click="confirmOutAndEnter">确定</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>

    <!-- 信息弹出 -->
    <el-dialog v-model="dialogVisibleInfo" :title="title" :close-on-click-modal="false" width="810px" center>
      <div class="info-car-data">
        <ul class="flex flex-w">
          <li class="flex">
            <label>车牌号：</label>
            <p>{{ carInfo.car_number }}</p>
          </li>
          <li class="flex">
            <label>车辆类型：</label>
            <p>{{ carInfo.type_name }}</p>
          </li>
          <li class="flex">
            <label>入场通道：</label>
            <p>{{ carInfo.ent_gate_name }}</p>
          </li>
          <li class="flex">
            <label>入场时间：</label>
            <p>{{ carInfo.ent_time }}</p>
          </li>
          <li class="flex">
            <label>出场通道：</label>
            <p>{{ carInfo.dep_gate_name }}</p>
          </li>
          <li class="flex">
            <label>停车时长：</label>
            <p>{{ carInfo.time_long || carInfo.park_time_long }}</p>
          </li>
          <li class="flex">
            <label>订单金额：</label>
            <p>{{ carInfo.all_price }}元</p>
          </li>
          <li class="flex">
            <label>已付金额：</label>
            <p>{{ (carInfo.all_price - carInfo.price).toFixed(2) }}</p>
          </li>
          <li class="flex" style="width:100%" v-if="carInfo.dep_pic">
            <label>抓拍图片：</label>
            <p>
              <el-image :src="carInfo.dep_pic" :preview-src-list="[carInfo.dep_pic]" />
            </p>
          </li>
          <li class="info-car-cost">
            <label>待缴金额:</label>
            <p>{{ carInfo.pay_price }}元</p>
          </li>
          <li class="flex">
            <label>优惠金额:</label>
            <p>{{ carInfo.cut_price }}元</p>
          </li>
        </ul>
        <div class="info-car-button flex">
          <el-button type="primary" border :loading="prePayLoading" @click="handlePrePay(carInfo)">现金放行</el-button>
        </div>
        <el-form class="info-car-reason" :inline="true">
          <el-form-item label="免费原因：">
            <el-select filterable allow-create default-first-option v-model="freeOut" placeholder="请选择免费原因"
              style="width:267px">
              <el-option v-for="(item, index) in freeOutList" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" :loading="freeOutLoading"
              @click="handleFreeOut(carInfo)">免费放行</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 禁止入场记录弹窗 -->
    <el-dialog v-model="dialogVisible" title="禁止入场记录" :close-on-click-modal="false" width="710px" center>
      <!-- <el-form ref="searchForm" :model="searchForm" :inline="true" @keyup.enter="getData(type)">
        <el-form-item label="车牌号" prop="car_number">
          <el-input v-model="searchForm.car_number" placeholder="请输入车牌号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getData">搜索</el-button>
        </el-form-item>
      </el-form> -->
      <el-table v-loading="loading" :data="tableData" :max-height="maxH" empty-text="暂无数据" style="width: 100%">
        <el-table-column prop="car_number" label="车牌号" width="130"> </el-table-column>
        <el-table-column prop="ent_gate_name" label="入场通道"> </el-table-column>
        <el-table-column prop="ent_time" label="时间"> </el-table-column>
        <el-table-column prop="memo" label="备注"> </el-table-column>
        <el-table-column label="" width="100">
          <template #default="scope">
            <el-button type="primary" @click="handleCar(scope.row)" size="small">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination :hide-on-single-page="false" layout="prev, pager, next" :page-size="searchForm.per_page" :total="total"
        @current-change="currentChange">
      </el-pagination> -->
    </el-dialog>
  </div>
</template>

<script>
import * as API_index from "@/api/index";

import carNumber from '@/components/car-number'
import { setTimeout } from 'timers';

export default {
  name: '',
  components: {
    carNumber
  },
  props: {
    title: {
      type: [String, Number],
      default: ''
    },
    close: {
      type: Boolean,
      default: true
    },
    freeOutList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      dialogVisible: false, //禁止车辆列表弹窗
      loading: false,
      searchForm: {
        park_id: this.getStore('park_id'),
        car_number: '',
        page: 1,
        per_page: 5,
      },
      total: 0,
      tableData: [],
      type: '',  //出入场类型

      dialogVisibleLookup: false,
      gateList: [], //出入口列表
      openthegate: true, //是否开闸
      lookupForm: {
        car_number: '',
        pic: '',
        park_id: this.getStore('park_id'),
        gate_id: '',
        actual_open: 1,
        ent_time: '',
        memo: '',
        prop_rule_id: '',
      },
      lookuRules: {
        car_number: [
          {
            required: true,
            message: '请输入车牌号',
          },
        ],
        ent_time: [
          {
            required: true,
            message: '请选择入场时间',
          },
        ],
        gate_id: [
          {
            required: true,
            message: '请选择入口通道',
          },
        ],
        prop_rule_id: [
          {
            required: true,
            message: '请选择计费规则',
          },
        ],
      },
      freeOut: '', //免费放行原因

      ruleList: [],
      dialogVisibleInfo: false,
      carInfo: '', //车辆信息
      outAndEnterLoading: false, //入场出场状态
      prePayLoading: false, //现金放行状态
      freeOutLoading: false, //免费放行状态
    }
  },
  created() {
  },
  mounted() {
  },
  watch: {
    dialogVisibleLookup(newState) {
      if (!newState) {
        this.$refs.elForm.resetFields()
        this.openthegate = true
        // this.lookupForm.actual_open = 1
        this.$refs.carNumber.carNumber = ''
      }
    }

  },
  methods: {
    init(type, list, data) {

      this.type = type
      if (this.type == 'out') {
        this.dialogVisibleLookup = true
        this.lookupForm.car_number = data
        setTimeout(() => {
          this.$refs.carNumber.carNumber = data || ''
        }, 300)
        API_index.getParkPropRule({ park_id: this.lookupForm.park_id, type: 1 }).then(res => {
          this.ruleList = res
          this.lookupForm.prop_rule_id = this.ruleList[0].id
        })
      } else if (this.type == 'enter') {
        this.dialogVisibleLookup = true
      } else if (this.type == 'pending') {
        this.dialogVisibleInfo = true
        this.freeOut = this.freeOutList[0]
        this.carInfo = data

      }
      if (list) {
        this.gateList = list
        this.lookupForm.gate_id = this.gateList[0].id
      }
    },
    currentChange(e) {
      this.searchForm.page = e
      this.getData()
    },
    handleOpenthegate(e) {
      console.log(e)
      this.lookupForm.actual_open = e ? 1 : 0
    },
    getData() {
      this.loading = true
      this.dialogVisible = true
      API_index.getRecordForbidden(this.searchForm).then(res => {
        this.loading = false
        this.tableData = res.data
        this.total = res.total
      })
    },
    handleBepresent() {
      this.$emit('upInRecord')
    },
    handleChange(file) {
      const a = new FileReader()
      a.readAsDataURL(file.raw)
      a.onload = (e) => {
        let base64 = e.target.result
        console.log(base64)
        this.lookupForm.pic = base64
      }
    },
    handlePrePay(e) {
      let form = {
        park_id: e.park_id,
        gate_id: e.dep_gate_id,
        car_number: e.car_number,
        pay_price: e.pay_price,
        pay_type: '现金',
        record_id: e.record_id,
        prop_rule_id: e.prop_rule_id,
        gate_open_log_id: e.gate_open_log_id,
        dep_param_id: e.dep_param_id,
        sign: e.sign,
        actual_open: this.lookupForm.actual_open,
      }
      this.prePayLoading = true
      API_index.getRecordAdminPrePay(form).then(() => {
        this.dialogVisibleInfo = false
        this.prePayLoading = false
        // this.$emit('upInRecord')
        this.$emit('release', form, false)
        this.$message({
          type: 'success',
          message: '已放行',
        })
      }).catch(() => {
        this.prePayLoading = false
      })
    },
    handleFreeOut(e) {
      if (!this.freeOut) {
        this.$message({
          type: 'error',
          message: '请选择免费放行原因',
        })
        return false
      }
      let form = {
        park_id: e.park_id,
        gate_id: e.dep_gate_id,
        car_number: e.car_number,
        record_id: e.record_id,
        memo: this.freeOut,
        actual_open: this.lookupForm.actual_open,

      }
      this.freeOutLoading = true
      API_index.postRecordManualFreeOut(form).then(() => {
        this.dialogVisibleInfo = false
        this.freeOutLoading = false
        // this.$emit('upInRecord')
        this.$emit('release', form, false)

        this.$message({
          type: 'success',
          message: '已免费放行',
        })
        this.freeOut = ''
      }).catch(() => {
        this.freeOutLoading = false
      })
    },
    handleCar(e) {
      console.log(e)
      this.lookupForm.ent_time = e.ent_time
      this.lookupForm.car_number = e.car_number
      this.lookupForm.pic = e.ent_pic
      this.$refs.carNumber.carNumber = e.car_number
      let index = true
      for (let i in this.gateList) {
        if (this.gateList[i].id == e.ent_gate_id) {
          this.lookupForm.gate_id = e.ent_gate_id
          index = false
          break
        }
      }
      if (index) {
        this.lookupForm.gate_id = ''
      }

      this.dialogVisible = false
    },
    confirmOutAndEnter() {
      this.$refs.elForm.validate((valid) => {
        if (valid) {


          let ApiUrl = ''
          if (this.type == 'out') {
            ApiUrl = API_index.postRecordManualOut
          } else {
            ApiUrl = API_index.postRecordManualIn
          }
          this.outAndEnterLoading = true
          ApiUrl(this.lookupForm).then(res => {
            this.dialogVisibleLookup = false
            this.outAndEnterLoading = false

            this.$refs.carNumber.carNumber = ''
            if (this.type == 'out') {
              this.dialogVisibleInfo = true
              this.freeOut = this.freeOutList[0]
              this.carInfo = { ...res.record, ...res.price_info }
            }
          }).catch(() => {
            this.outAndEnterLoading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.form-lookup {
  .ban-record {
    margin-left: 10px;
    color: $primary;
    cursor: pointer;
  }

  .el-button {
    width: 195px;
  }
}

.pic {
  position: relative;
  width: 148px;
  height: 148px;

  i {
    position: absolute;
    right: -30px;
    top: -8px;
    color: #f00;
    font-size: 30px;
    cursor: pointer;
  }
}

.info-car-data {
  padding: 20px 0;
  color: $color;

  li {
    width: 50%;
    line-height: 40px;

    label {
      width: 5em;
    }

    .el-image {
      width: 100px;
      height: 60px;
      margin-top: 6px;
    }

    &.info-car-cost {
      padding: 20px 0;

      p {
        display: inline-block;
        margin-top: 20px;
        font-size: 60px;
        color: $danger;
      }
    }
  }

  .info-car-button {
    margin-bottom: 20px;

    .el-button {
      width: 146px;
      height: 55px;
      border-radius: 0;
    }
  }
}
</style>